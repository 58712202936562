<template>
  <header>
    <div class="nav container" ref="header">
      <img src="../assets/img/web-logo.png" @click="toWeb">
      <div class="info">
        <span v-if="!address"> <i class="err"></i> 未登录</span>
        <span v-else> <i></i> {{ address | addressCharacter(6, 6) }}</span>
        <img class="meun" src="../assets/img/meun.png" @click="drawer = true">
      </div>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="drawer"
      :direction="'rtl'"
      :size="'100%'">
      <ul class="meun-list">
        <li @click="drawer = false">
          <router-link :to="{path: '/node', query:{referee}}">初始节点</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/fomo', query:{referee}}">Fomo</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/USDswap', query:{referee}}">USD兑换</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/buyminer', query:{referee}}">投入挖矿(WF)</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/myreward', query:{referee}}">我的收益</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/wfswap', query:{referee}}">WF兑换</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/team', query:{referee}}">我的团队</router-link>
        </li>
        <li @click="drawer = false">
          <router-link :to="{path: '/fomomessage', query:{referee}}">{{ $t('news.message') }}</router-link>
        </li>

      </ul>
      <div class="drawer-close" @click="drawer = false"></div>
    </el-drawer>
  </header>
</template>

<script>
// import { ethers } from 'ethers'
import { initSigner } from '../utlis/index'
export default {
  name: 'Dapp-Header',
  data () {
    return {
      drawer: false,
      referee: '',
      address: '',
    }
  },
  mounted () {
    this.referee = this.$route.query.referee || '0x0000000000000000000000000000000000000000'
    this.init()
  },
  methods: {
    async init () {
      let { address } = await initSigner()
      this.address = address
    },
    toWeb () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  // color: #FFF;
  font-size: 0.28rem;
  ::v-deep .el-drawer {
    background-color: #000;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.5rem 0.2rem;
    z-index: 10;
    img {
      width: 1.14rem;
      height: 0.7rem;
      cursor: pointer;
    }
    .info {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        padding: 0.15rem 0.17rem 0.15rem 0.2rem;
        box-sizing: border-box;
        border: 1px solid #45BBF3;
        background-color: rgba(85, 114, 241, 0.2);
        font-size: 0.24rem;
        color: #FFFF;
        i {
          display: inline-block;
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.1rem;
          background: url('../assets/img/address-icon.png') left top / 100% 100% no-repeat;
          &.err {
            background-image: url('../assets/img/addr-err.png');
          }
        }
      }
      .meun {
        z-index: 9999;
        width: 0.6rem;
        height: 0.46rem;
        cursor: pointer;
        margin-left: 0.34rem;
      }
    }
  }

  .meun-list {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    padding: 80px 0 0;
    li {
      width: 4.4rem;
      height: 1rem;
      color: #FFF;
      text-align: center;
      font-size: 00.28rem;
      line-height: 1rem;
      box-sizing: border-box;
      border-bottom: 1px solid #BBB;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
      }
    }
  }

  .drawer-close {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    width: 0.4rem;
    height: 0.4rem;
    background: url('../assets/img/close-icon.png') left top / 100% 100% no-repeat;
    font-size: 0.5rem;
    color: #FFF;
    cursor: pointer;
  }
}
</style>
